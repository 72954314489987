html,
body {
  height: 100%;
  margin: 0;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  color: #1B1B1E;
  font-weight: 400;
}
/*  Chrome buttons do not respect body system font */
button {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}
#__next {
  height: 100%;
  display: flex;
  flex-direction: column;
}

a {
  color: blue;
}
a:hover {
  text-decoration: none;
}
* {
  box-sizing: border-box;
}
input {
  font-size: 1rem;
}
h1 {
  font-size: 24px;
}
.react-datepicker-wrapper input {
  padding: 1rem;
  color: #1B1B1E;
  border: 1px solid #C0C0C0;
  border-radius: 8px;
}
.react-datepicker-wrapper input:focus {
  border-color: #493EE0;
}
