.shareButton {
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.notification {
  background-color: #514F63;
  color: #fff;
  font-weight: 400;
  border-radius: 100px;
  padding: 8px 20px;
  font-size: 14px;
}